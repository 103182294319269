<template>
    <b-card title="Add GST">
        <!-- <b-col cols="12">
            <label>Name</label>
            <b-form-input placeholder="Name" v-model="form.name"></b-form-input>
        </b-col> -->

        <b-col cols="12">
            <label>
                Name<span style="color:red">(*)</span>
            </label>

            <b-form-input
                v-model="form.name" placeholder="Name">
            </b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Value(%)<span style="color:red">(*)</span></label>
            <b-form-input placeholder="Value(%)" v-model="form.value"></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Start Date<span style="color:red">(*)</span></label>
            <b-form-datepicker dropright placeholder="Select Date" v-model="form.startDate"></b-form-datepicker>
        </b-col>

        <b-col cols="12" class="mt-2">
            <b-button variant="success" @click="addGST">Save</b-button>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import axios from '@/axios';

export default {
    data() {
        return {
            form: {

            },
        }
    },
    mounted() {
        // this.getCategories();
        
    },
    methods: {
        addGST() {
            axios.post('sales-and-purchase/drcc/gst',this.form).then((res) => {
                console.log(res)
                this.$router.go(-1);
            }).catch((err) => {
                console.error(err)
                this.$bvToast.toast(`${err.response.data.errors[0].message}`, {
                title: "Failed",
                variant: "danger",
                solid: true,
        })
            })
        }
        
    },
    computed: {
        ...mapState({
            'categories': (state) => {
                return state.category.categories.map((c) => ({ value: c.id, text: c.name }))
            }
        })
    }
}
</script>